import actionTypes from "../../redux/actionTypes";
import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../../redux/initialState";
import ContentfulService from "../../services/contentfulService";
import { ServiceType } from "./serviceTypeTypes";
import ContentType from "../../services/contentTypes";

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, ServiceType, Action<string>>>;

export const fetchServiceType: AppThunk = () => {
  return async (dispatch: Dispatch): Promise<Action> => {
    try {
      const contentfulService = new ContentfulService();
      const entry: any = await contentfulService.getContentType(ContentType.SERVICE_TYPE);
      const categoryArr: ServiceType[] = entry.items.map(
        (item: { sys: { id: string }; fields: { externalName: string; internalName: string; codeId: string } }) => ({
          id: item.sys.id,
          internalName: item.fields.internalName,
          externalName: item.fields.externalName,
          codeId: item.fields.codeId,
          isChecked: false,
        }),
      );
      return dispatch({
        type: actionTypes.LOAD_SERVICE_TYPE_SUCCESS,
        payload: categoryArr,
      });
    } catch (e) {
      return dispatch({
        type: actionTypes.LOAD_SERVICE_TYPE_ERROR,
        payload: e,
      });
    }
  };
};
