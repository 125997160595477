import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./App.css";

import { GelThemeProvider, useThemeSelector } from "@tal-gel/theming";
import { GelScreenDetector, GelPageLayout, GelPageHeader, GelContainerLite, GelPageContent, GelPageFooter } from "@tal-gel/components";

import RouteObj from "./route";
import { Route, Switch } from "react-router-dom";
import { connect, Provider } from "react-redux";
import { ApplicationState } from "./redux/initialState";
import { AnyAction, Store } from "redux";
import { History } from "history";
import { ConnectedRouter } from "connected-react-router";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import FullPageLoader from "./components/fullPageLoader/fullpageLoader";
import { PartnerLogo } from "./features/partnerLogo/partnerLogoTypes";
import { fetchPartnerLogo } from "./features/partnerLogo/partnerLogoActions";
import { ThunkDispatch } from "redux-thunk";
import DynatraceScript from "./script/Dynatrace";

interface MainProps {
  store: Store<ApplicationState>;
  history: History;
  loading: boolean;
  data: PartnerLogo;
  errors?: string;
}

interface propsFromDispatch {
  fetchPartnerLogo: (PartnerLogo: any) => any;
}

type AllProps = MainProps & propsFromDispatch;

const App: React.FC<AllProps> = ({ store, history, loading, errors, data, fetchPartnerLogo }) => {
  const path = window.location.pathname.substr(1);
  const [theme, setTheme] = useThemeSelector("tal");

  useEffect(() => {
    if (path) {
      fetchPartnerLogo(path);
    }
  }, [fetchPartnerLogo, path]);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      (link as HTMLAnchorElement).rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    DynatraceScript();

    if (path.includes("amp")) {
      setTheme("amp");
      (link as HTMLAnchorElement).href = "./ampfavicon.ico";
    } else {
      setTheme("tal");
      (link as HTMLAnchorElement).href = "./favicon.ico";
    }
  }, [path]);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {(loading || !path) && (
          <GelThemeProvider mode={process.env.NODE_ENV} extraScope={false}>
            <GelScreenDetector>
              <GelPageLayout>
                <GelPageHeader>
                  <div style={{ borderBottom: !path.includes("amp") ? "1px solid #e1e1e1" : "" }}>
                    <Header {...data} />
                  </div>
                </GelPageHeader>
                <GelPageContent>
                  <GelContainerLite
                    style={{
                      backgroundColor: "#fff",
                      width: "100vw",
                    }}
                  >
                    <Switch>
                      {RouteObj.map((route, index) => {
                        return <Route key={index} exact={route.isExact} path={route.path} component={route.component} routeData={route} />;
                      })}
                    </Switch>
                  </GelContainerLite>
                </GelPageContent>
                <GelPageFooter>
                  <div className={"Footer"}>
                    <Footer />
                  </div>
                </GelPageFooter>
              </GelPageLayout>
            </GelScreenDetector>
          </GelThemeProvider>
        )}
        {!loading && path && <FullPageLoader />}
      </ConnectedRouter>
    </Provider>
  );
};

const mapStateToProps = ({ partnerLogo }: ApplicationState) => ({
  loading: partnerLogo.loading,
  errors: partnerLogo.errors,
  data: partnerLogo.data,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchPartnerLogo: (partnerLogo: PartnerLogo) => {
      dispatch(fetchPartnerLogo(partnerLogo));
    },
  };
};

App.propTypes = {
  store: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  loading: PropTypes.any,
  data: PropTypes.any.isRequired,
  errors: PropTypes.string,
  fetchPartnerLogo: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
