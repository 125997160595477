import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { ApplicationState } from "../../redux/initialState";
import OrganisationCard from "./organisationCard";
import getFilteredServices from "../../selectors/serviceSelector";

import { GelRowLayout, GelCardLayout } from "@tal-gel/components";
import { Organisation } from "../../features/organisation/organisationTypes";
import { fetchOrganisations } from "../../features/organisation/organisationActions";

interface PropsFromState {
  data: Organisation[];
}

interface propsFromDispatch {
  fetchOrganisations: () => any;
}

type AllProps = PropsFromState & propsFromDispatch;

const OrganisationList: React.FC<AllProps> = ({ data, fetchOrganisations }) => {
  const [firstLoad, setLoad] = useState<boolean>(false);
  useEffect(() => {
    if (!data.length && !firstLoad) {
      fetchOrganisations();
    }
    // we need this to not trigger fetching new organisation data from confluence after initial load
    if (data.length !== 0) {
      setLoad(true);
    }
  }, [data.length, fetchOrganisations]);

  return (
    <GelRowLayout gutter="small" style={{ maxWidth: "1080px" }}>
      <p>{data.length} results</p>
      <GelCardLayout
        fluid
        verticalMasonry={{
          xl: 3,
          lg: 3,
          md: 2,
          sm: 1,
          xs: 1,
        }}
        gap="large"
      >
        {data.map(item => (
          <OrganisationCard key={item.id} item={item} />
        ))}
      </GelCardLayout>
    </GelRowLayout>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    data: getFilteredServices(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchOrganisations: () => {
      dispatch(fetchOrganisations());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationList);
