import React from "react";
import "./header.css";
import { GelContainerLite, GelBoxLayout } from "@tal-gel/components";
import { PartnerLogo } from "../../features/partnerLogo/partnerLogoTypes";
import { getGelTokens } from "@tal-gel/theming";

const Header: React.FC<PartnerLogo> = (partnerLogo: PartnerLogo) => {
  return (
    <GelContainerLite
      gutter="medium"
      style={partnerLogo.slug === "amp" ? { background: getGelTokens().global.themeColorBackgroundInverse } : {}}
    >
      <GelBoxLayout space="[1,'auto']" distribution="start" gap="large" style={{ maxWidth: 1080 }}>
        <a className={"icon-link"} href={`/${partnerLogo.slug ? partnerLogo.slug : ""}`}>
          {partnerLogo.url ? (
            <GelBoxLayout space="auto" distribution="start" gap="small">
              <div className={"img-container"}>
                <img src={partnerLogo.url} alt={partnerLogo.slug} />
              </div>
            </GelBoxLayout>
          ) : (
            <img src={process.env.PUBLIC_URL + "/TAL.svg"} width="65px" height="24px" alt={"TAL Logo"} />
          )}
        </a>
      </GelBoxLayout>
    </GelContainerLite>
  );
};

export default Header;
