import { Reducer } from "redux";

import { HeroType, HeroTypeState } from "./heroTypes";
import actionTypes from "../../redux/actionTypes";
export const initialState: HeroTypeState = {
  data: {} as HeroType,
  errors: undefined,
  loading: false,
};

const heroTypeReducer: Reducer<HeroTypeState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_HERO_SUCCESS: {
      const heroContent = action.payload as HeroType;
      return { ...state, loading: true, data: heroContent };
    }
    default: {
      return state;
    }
  }
};

export default heroTypeReducer;
