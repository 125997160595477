import React from "react";
import Condition from "../../components/condition/condition";
import Location from "../../components/location/location";
import Cost from "../../components/cost/cost";
import ServiceTypeBox from "../serviceType/serviceTypeBox";

import { GelRowLayout, GelBoxLayout, GelHeading6 } from "@tal-gel/components";

const Filter = (): React.ReactElement => (
  <GelRowLayout gutter={"small"}>
    <GelHeading6 style={{ marginBottom: "16px", marginTop: "0" }}>Service Type</GelHeading6>
    <ServiceTypeBox />
    <GelRowLayout gutter="small">
      <GelBoxLayout distribution="auto" gutter="small">
        <GelRowLayout gutter="small">
          <GelHeading6 style={{ marginBottom: "8px", marginTop: "24px" }}>Location</GelHeading6>
          <Location />
        </GelRowLayout>
        <GelRowLayout gutter="small">
          <GelHeading6 style={{ marginBottom: "8px", marginTop: "24px" }}>Condition</GelHeading6>
          <Condition />
        </GelRowLayout>
        <GelRowLayout gutter="small">
          <GelHeading6 style={{ marginBottom: "8px", marginTop: "24px" }}>Cost</GelHeading6>
          <Cost />
        </GelRowLayout>
      </GelBoxLayout>
    </GelRowLayout>
  </GelRowLayout>
);

export default Filter;
