import React from "react";
import { GelRowLayout, GelParagraph, GelBoxLayout } from "@tal-gel/components";
import "../organisationCard.css";
import { Organisation } from "../../../features/organisation/organisationTypes";
import OrganisationServiceTypeInfo from "../organisationServiceTypeInfo";
import OrganisationModalAdditionalInfo from "./organisationModalAdditionalInfo";
import OrganisationContactInfo from "../organisationContactInfo";

interface propsFromComponent {
  item: Organisation;
}

type Props = propsFromComponent;

const OrganisationModal: React.FC<Props> = ({ item }) => {
  return (
    <GelRowLayout alignment={"start"}>
      <GelParagraph>{item.description}</GelParagraph>
      <GelBoxLayout distribution={["auto", 1]} alignment={"start"} width={700}>
        <GelRowLayout gutter="medium">
          <OrganisationContactInfo item={item} />
          <OrganisationServiceTypeInfo item={item} />
        </GelRowLayout>
        <OrganisationModalAdditionalInfo item={item} />
      </GelBoxLayout>
    </GelRowLayout>
  );
};

export default OrganisationModal;
