import { createClient } from "contentful";

export default class ContentfulService {
  getClient = async () => {
    const client = await createClient({
      space: "#{HC_CONTENTFUL_SPACE}#",
      environment: "#{HC_CONTENTFUL_ENV}#",
      accessToken: "#{HC_CONTENTFUL_TOKEN}#",
    });
    return client;
  };

  getContentType = async (contentType: string) => {
    const client = await this.getClient();
    const order = contentType === "serviceType" ? "fields.organisation" : "fields.externalName";
    const entry = await client.getEntries({
      include: 2,
      content_type: contentType,
      order: order, //use -fields.fieldname to play with sorting
    });
    return entry;
  };

  getContentTypeFilterBySlug = async (contentType: string, slug: string) => {
    const client = await this.getClient();
    const entry = await client.getEntries({
      include: 2,
      content_type: contentType,
      "fields.slug[match]": slug,
    });
    return entry;
  };

  getContentTypeFilterBySlugTheme = async (contentType: string, slug: string) => {
    const client = await this.getClient();
    const entry = await client.getEntries({
      include: 2,
      content_type: contentType,
      "metadata.tags.sys.id[in]": slug,
    });
    return entry;
  };
}
