import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import PropTypes from "prop-types";

import { fetchState } from "../../features/state/stateActions";
import { ApplicationState } from "../../redux/initialState";
import { State } from "../../features/state/stateTypes";

import { GelSelect } from "@tal-gel/components";
import { SelectedLocation } from "../../features/locationFilter/selectLocationTypes";
import { updateLocationFilter } from "../../features/locationFilter/selectLocationActions";

interface PropsFromState {
  loading: boolean;
  data: State[];
  errors?: string;
}

interface propsFromDispatch {
  fetchState: () => any;
  updateLocationFilter: (SelectedLocation: any) => any;
}

type AllProps = PropsFromState & propsFromDispatch;

const LocationSelect: React.FC<AllProps> = ({ loading, errors, data, fetchState, updateLocationFilter }) => {
  //needed to remember values when open / close mobile modal
  const [value, setValue] = useState<string>(useSelector<ApplicationState>(state => state.selectedLocation.data) as string);

  useEffect(() => {
    if (!data.length) {
      fetchState();
    }
  }, [data.length, fetchState]);

  const UpdateFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    const selectedLocation: SelectedLocation = {
      id: e.target.value,
    };
    updateLocationFilter(selectedLocation);
  };

  return (
    <GelSelect
      width={300}
      placeholder="Select"
      options={data.map(item => ({ value: item.id, label: item.externalName }))}
      onChange={UpdateFilter}
      value={value}
    />
  );
};

const mapStateToProps = ({ state }: ApplicationState) => ({
  loading: state.loading,
  errors: state.errors,
  data: state.data,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchState: () => {
      dispatch(fetchState());
    },
    updateLocationFilter: (selectedLocation: SelectedLocation) => {
      dispatch(updateLocationFilter(selectedLocation));
    },
  };
};

LocationSelect.propTypes = {
  loading: PropTypes.any,
  data: PropTypes.any.isRequired,
  errors: PropTypes.string,
  fetchState: PropTypes.func.isRequired,
  updateLocationFilter: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelect);
