import { Reducer } from "redux";

import { SelectedCondition, SelectedConditionState } from "./selectedConditionTypes";
import actionTypes from "../../redux/actionTypes";
export const initialState: SelectedConditionState = {
  data: [],
};

const selectedConditionReducer: Reducer<SelectedConditionState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CONDITION_FILTER_SUCCESS: {
      const conditionFilterUpdated = action.payload as SelectedCondition;
      return { ...state, data: conditionFilterUpdated.data };
    }
    default: {
      return state;
    }
  }
};

export default selectedConditionReducer;
