import React from "react";

const PageNotFound = () => (
  <div>
    <h2>Urgh...</h2>
    <p>You are lost in the clouds...</p>
  </div>
);

export default PageNotFound;
