import { Reducer } from "redux";

import { PartnerLogo, PartnerLogoState } from "./partnerLogoTypes";
import actionTypes from "../../redux/actionTypes";
export const initialState: PartnerLogoState = {
  data: {} as PartnerLogo,
  errors: undefined,
  loading: false,
};

const selectedConditionReducer: Reducer<PartnerLogoState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PARTNER_LOGO_SUCCESS: {
      const partnerLogo = action.payload as PartnerLogo;
      return { ...state, loading: true, data: partnerLogo };
    }
    default: {
      return state;
    }
  }
};

export default selectedConditionReducer;
