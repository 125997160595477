import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import PropTypes from "prop-types";

import { fetchEligibilityConditions } from "../../features/condition/conditionActions";
import { ApplicationState } from "../../redux/initialState";
import { Condition } from "../../features/condition/conditionTypes";
import { SelectedCondition } from "../../features/conditionFilter/selectedConditionTypes";
import { updateConditionFilter } from "../../features/conditionFilter/selectedConditionActions";

import { GelDropdown } from "@tal-gel/components";

interface PropsFromState {
  loading: boolean;
  data: Condition[];
  errors?: string;
}

interface propsFromDispatch {
  fetchEligibilityConditions: () => any;
  updateConditionFilter: (SelectedCondition: any) => any;
}

type AllProps = PropsFromState & propsFromDispatch;

const ConditionSelect: React.FC<AllProps> = ({ loading, errors, data, fetchEligibilityConditions, updateConditionFilter }) => {
  //needed to remember values when open / close mobile modal
  const [value, setValue] = useState<string[]>(useSelector<ApplicationState>(state => state.selectedCondition.data) as string[]);

  useEffect(() => {
    if (!data.length) {
      fetchEligibilityConditions();
    }
  }, [data.length, fetchEligibilityConditions]);
  const UpdateFilter = (event: any) => {
    setValue(event.target.value);
    const selectedCondition: SelectedCondition = {
      data: event.target.value,
    };
    updateConditionFilter(selectedCondition);
  };

  return (
    <GelDropdown
      multiSelect
      width={300}
      placeholder="Select"
      options={data.map(item => ({ value: item.id, label: item.externalName }))}
      optionSelectAll="All Conditions"
      onChange={UpdateFilter}
      value={value}
    />
  );
};

const mapStateToProps = ({ condition }: ApplicationState) => ({
  loading: condition.loading,
  errors: condition.errors,
  data: condition.data,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchEligibilityConditions: () => {
      dispatch(fetchEligibilityConditions());
    },
    updateConditionFilter: (selectedCondition: SelectedCondition) => {
      dispatch(updateConditionFilter(selectedCondition));
    },
  };
};

ConditionSelect.propTypes = {
  loading: PropTypes.any,
  data: PropTypes.any.isRequired,
  errors: PropTypes.string,
  fetchEligibilityConditions: PropTypes.func.isRequired,
  updateConditionFilter: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConditionSelect);
