import { createSelector } from "reselect";
import { Condition } from "../features/condition/conditionTypes";
import { Cost } from "../features/cost/costTypes";

import { Organisation, OrganisationServiceType } from "../features/organisation/organisationTypes";
import { ServiceType } from "../features/serviceType/serviceTypeTypes";
import { State } from "../features/state/stateTypes";

import { ApplicationState } from "../redux/initialState";
import { reportSearchFilterApplied } from "../services/utmService";

export const getServices = (state: ApplicationState) => state.service.data;

export const getOrganisations = (state: ApplicationState) => state.organisation.data;

export const getServiceTypes = (state: ApplicationState) => state.serviceType.data;

export const getState = (state: ApplicationState) => state.state.data;

export const getCondition = (state: ApplicationState) => state.condition.data;

export const getCost = (state: ApplicationState) => state.cost.data;

export const getSelectedServiceTypes = (state: ApplicationState) => state.selectedServiceType.data;
export const getSelectedLocations = (state: ApplicationState) => state.selectedLocation.data;
export const getSelectedConditions = (state: ApplicationState) => state.selectedCondition.data;
export const getSelectedCosts = (state: ApplicationState) => state.selectedCost.data;

export type FilteredServices = ReturnType<typeof getFilteredServices>;

const getFilteredServices = (
  organisations: Organisation[],
  selectedServiceTypes: string[],
  selectedLocation: string,
  selectedConditions: string[],
  selectedCosts: string[],
  allServiceTypes: ServiceType[],
  allStates: State[],
  allConditions: Condition[],
  allCosts: Cost[],
) => {
  if (
    organisations.length !== 0 &&
    (selectedServiceTypes.length !== 0 || selectedLocation !== "" || selectedConditions.length !== 0 || selectedCosts.length !== 0)
  ) {
    const cloneSelectedServiceTypes = Object.assign([], selectedServiceTypes);
    const cloneSelectedConditions = Object.assign([], selectedConditions);
    const cloneSelectedLocation = selectedLocation;
    const cloneSelectedCosts = Object.assign([], selectedCosts);

    const selectedOrganisations: Organisation[] = getFilteredOrganisation(
      organisations,
      cloneSelectedServiceTypes,
      cloneSelectedCosts,
      cloneSelectedLocation,
      cloneSelectedConditions,
    );

    //if filtered options are used, send to Adobe Analytics
    reportSearchFilterApplied(
      selectedOrganisations,
      selectedServiceTypes,
      selectedLocation,
      selectedConditions,
      selectedCosts,
      allServiceTypes,
      allStates,
      allConditions,
      allCosts,
    );
    return selectedOrganisations;
  }
  return organisations;
};

const getFilteredOrganisation = (organisations: Organisation[], serviceTypes: any, costs?: any, location?: any, condition?: any) => {
  const selectedOrganisationsByFilter = organisations.filter(organisation => {
    const orgServiceTypes = organisation.serviceTypes.filter(serviceType => {
      return filterOnServiceType(serviceType, serviceTypes) && filterOnCost(serviceType, costs) && filterOnLocation(serviceType, location);
    });
    return orgServiceTypes && orgServiceTypes.length > 0 && filterOnCondition(organisation, condition);
  });
  return selectedOrganisationsByFilter;
};

const filterOnServiceType = (serviceType: OrganisationServiceType, serviceTypes: string[]) => {
  if (!serviceTypes || serviceTypes.length === 0) return true;
  const foundServiceType = serviceTypes.includes(serviceType.serviceType.id);
  return foundServiceType;
};

const filterOnCondition = (organisation: Organisation, conditions: string[]) => {
  if (!conditions || conditions.length === 0) return true;
  const foundConditions = organisation.conditions.filter(condition => {
    return conditions.includes(condition.id);
  });
  return foundConditions.length > 0;
};

const filterOnCost = (serviceType: OrganisationServiceType, costs: string[]) => {
  if (!costs || costs.length === 0) return true;
  const foundCost = costs.includes(serviceType.cost.id);
  return foundCost;
};

const filterOnLocation = (serviceType: OrganisationServiceType, location: string) => {
  if (!location) return true;
  return serviceType.states.filter(state => state.id === location).length > 0;
};

export default createSelector(
  getOrganisations,
  getSelectedServiceTypes,
  getSelectedLocations,
  getSelectedConditions,
  getSelectedCosts,
  getServiceTypes,
  getState,
  getCondition,
  getCost,
  getFilteredServices,
);
