import React, { useContext, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import PropTypes from "prop-types";

import { fetchServiceType } from "../../features/serviceType/serviceTypeActions";
import { updateServiceTypeFilter } from "../../features/serviceTypeFilter/selectedServiceTypeActions";
import { ApplicationState } from "../../redux/initialState";
import { ServiceType } from "../../features/serviceType/serviceTypeTypes";
import { SelectedServiceType } from "../../features/serviceTypeFilter/selectedServiceTypeTypes";

import { GelCheckbox, GelCheckboxGroup, GelColumnLayout, GelScreenDetectorContext } from "@tal-gel/components";

interface PropsFromState {
  loading: boolean;
  data: ServiceType[];
  errors?: string;
}

interface propsFromDispatch {
  fetchServiceType: () => any;
  updateServiceTypeFilter: (ServiceTypeFilter: any) => any;
}

type AllProps = PropsFromState & propsFromDispatch;

const ServiceTypeBox: React.FC<AllProps> = ({ loading, errors, data, fetchServiceType, updateServiceTypeFilter }) => {
  //needed to remember values when open / close mobile modal
  const [values] = useState<string[]>(useSelector<ApplicationState>(state => state.selectedServiceType.data) as string[]);

  useEffect(() => {
    if (!data.length) {
      fetchServiceType();
    }
  }, [data.length, fetchServiceType]);

  const UpdateFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedServiceType: SelectedServiceType = {
      id: e.target.id,
      isChecked: e.target.checked,
    };
    updateServiceTypeFilter(selectedServiceType);
  };

  const { screen, isLargerThanMdScreen } = useContext(GelScreenDetectorContext);

  return (
    <GelCheckboxGroup onChange={UpdateFilter}>
      <GelColumnLayout distribution={isLargerThanMdScreen(screen) ? "1/3 1/3 1/3" : "1/2 1/2"} verticalGutter="medium">
        {data.map(item => (
          <GelCheckbox key={item.id} id={item.id} value={item.externalName} defaultChecked={values.includes(item.id)}>
            {item.externalName}
          </GelCheckbox>
        ))}
      </GelColumnLayout>
    </GelCheckboxGroup>
  );
};

const mapStateToProps = ({ serviceType }: ApplicationState) => ({
  loading: serviceType.loading,
  errors: serviceType.errors,
  data: serviceType.data,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchServiceType: () => {
      dispatch(fetchServiceType());
    },
    updateServiceTypeFilter: (selectedServiceType: SelectedServiceType) => {
      dispatch(updateServiceTypeFilter(selectedServiceType));
    },
  };
};

ServiceTypeBox.propTypes = {
  loading: PropTypes.any,
  data: PropTypes.any.isRequired,
  errors: PropTypes.string,
  fetchServiceType: PropTypes.func.isRequired,
  updateServiceTypeFilter: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTypeBox);
