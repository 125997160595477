export interface State {
  id: string;
  internalName: string;
  externalName: string;
  codeId: string;
}

export interface StateState {
  readonly loading: boolean;
  readonly data: State[];
  readonly errors?: string;
}

export enum LocationLabel {
  LIMITED = "Limited locations",
  NATIONWIDE = "Nationwide",
}
