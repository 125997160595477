import React from "react";
import "./fullpageLoader.css";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function FullpageLoader() {
  return (
    <div
      style={{ width: "100%", height: "100vh", backgroundColor: "#fff", display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <div className="sk-chase">
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    </div>
  );
}

export default FullpageLoader;
