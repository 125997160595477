import actionTypes from "../../redux/actionTypes";
import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../../redux/initialState";
import { SelectedCondition } from "./selectedConditionTypes";

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, SelectedCondition, Action<string>>>;

export const updateConditionFilter: ActionCreator<ThunkAction<void, ApplicationState, any, Action<string>>> = selectedCondition => {
  return (dispatch: Dispatch): Action => {
    try {
      return dispatch({
        type: actionTypes.UPDATE_CONDITION_FILTER_SUCCESS,
        payload: selectedCondition,
      });
    } catch (e) {
      return dispatch({
        type: actionTypes.UPDATE_CONDITION_FILTER_ERROR,
        payload: null,
      });
    }
  };
};
