import actionTypes from "../../redux/actionTypes";
import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../../redux/initialState";
import ContentfulService from "../../services/contentfulService";
import { State } from "./stateTypes";
import ContentType from "../../services/contentTypes";

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const fetchState: AppThunk = () => {
  return async (dispatch: Dispatch): Promise<Action> => {
    try {
      const contentfulService = new ContentfulService();
      const entry: any = await contentfulService.getContentType(ContentType.SERVICE_STATE);
      const stateArr: State[] = entry.items.map(
        (item: { sys: { id: string }; fields: { internalName: string; externalName: string; codeId: string } }) => ({
          id: item.sys.id,
          internalName: item.fields.internalName,
          externalName: item.fields.externalName,
          codeId: item.fields.codeId,
        }),
      );
      return dispatch({
        type: actionTypes.LOAD_STATE_SUCCESS,
        payload: stateArr,
      });
    } catch (e) {
      return dispatch({
        type: actionTypes.LOAD_STATE_ERROR,
        payload: e,
      });
    }
  };
};
