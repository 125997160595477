import React from "react";
import { GelRowLayout, GelIcon, GelLink, GelBoxLayout } from "@tal-gel/components";
import "./organisationCard.css";
import { Organisation } from "../../features/organisation/organisationTypes";
import { reportCardLinkClicked } from "../../services/utmService";
import { getGelTokens } from "@tal-gel/theming";
interface propsFromComponent {
  item: Organisation;
}

type Props = propsFromComponent;

const OrganisationContactInfo: React.FC<Props> = ({ item }) => {
  const phoneLink = "tel:" + item.phoneNumber;

  return (
    <GelRowLayout alignment={"start"} gutter={"xsmall"}>
      <GelBoxLayout
        space={["auto", 1]}
        distribution="start"
        gap="xsmall"
        gutter={"xsmall"}
        style={{ display: item.website ? "flex" : "none" }}
        alignment={"start"}
      >
        <GelIcon
          name="ContactsSocialWebsite"
          brand
          color={getGelTokens().global.themeColorIconDefault}
          width="18"
          height="18"
          style={{ paddingTop: 1 }}
        />
        <GelLink
          style={{ fontSize: 15 }}
          href={item.website}
          target="_blank"
          onClick={() => {
            reportCardLinkClicked(item.website, item.externalName);
          }}
        >
          {item.externalName} Website
        </GelLink>
      </GelBoxLayout>
      <GelBoxLayout
        space={["auto", 1]}
        distribution="start"
        gap="xsmall"
        gutter={"xsmall"}
        alignment={"start"}
        style={{ display: item.phoneNumber ? "flex" : "none" }}
      >
        <GelIcon name="Phone" color={getGelTokens().global.themeColorIconDefault} width="18" height="18" style={{ paddingTop: 1 }} />
        <GelLink style={{ fontSize: 15 }} href={phoneLink} onClick={() => reportCardLinkClicked(phoneLink, item.externalName)}>
          {item.phoneNumber}
        </GelLink>
      </GelBoxLayout>
    </GelRowLayout>
  );
};

export default OrganisationContactInfo;
