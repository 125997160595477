const DynatraceScript = () => {
  const scriptSource =
    process.env.NODE_ENV === "production"
      ? "https://js-cdn.dynatrace.com/jstag/161f5a57e21/bf07938auq/19d70a823fbaef78_complete.js"
      : "https://js-cdn.dynatrace.com/jstag/161f5a57e21/bf74908luu/c9275ab879e63a3_complete.js";
  const script = document.createElement("script");
  script.setAttribute("type", "text/javascript");
  script.setAttribute("src", scriptSource);
  script.setAttribute("crossorigin", "anonymous");
  document.head.appendChild(script);
};

export default DynatraceScript;
