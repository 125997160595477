import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import PropTypes from "prop-types";

import { fetchCosts } from "../../features/cost/costActions";
import { ApplicationState } from "../../redux/initialState";
import { Cost } from "../../features/cost/costTypes";
import { SelectedCost } from "../../features/costFilter/selectedCostTypes";
import { updateCostFilter } from "../../features/costFilter/selectedCostActions";

import { GelDropdown } from "@tal-gel/components";

interface PropsFromState {
  loading: boolean;
  data: Cost[];
  errors?: string;
}

interface propsFromDispatch {
  fetchCosts: () => any;
  updateCostFilter: (SelectedCost: any) => any;
}

type AllProps = PropsFromState & propsFromDispatch;

const CostSelect: React.FC<AllProps> = ({ loading, errors, data, fetchCosts, updateCostFilter }) => {
  //needed to remember values when open / close mobile modal
  const [values, setValues] = useState<string[]>(useSelector<ApplicationState>(state => state.selectedCost.data) as string[]);

  useEffect(() => {
    if (!data.length) {
      fetchCosts();
    }
  }, [fetchCosts, data.length]);

  const UpdateFilter = (event: any) => {
    setValues(event.target.value);
    const selectedCost: SelectedCost = {
      data: event.target.value,
    };

    updateCostFilter(selectedCost);
  };
  return (
    <GelDropdown
      multiSelect
      width={300}
      placeholder="Select"
      optionSelectAll="All Cost"
      options={data.map(item => ({ value: item.id, label: item.externalName }))}
      onChange={UpdateFilter}
      value={values}
    />
  );
};

const mapStateToProps = ({ cost }: ApplicationState) => ({
  loading: cost.loading,
  errors: cost.errors,
  data: cost.data,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchCosts: () => {
      dispatch(fetchCosts());
    },
    updateCostFilter: (selectedCost: SelectedCost) => {
      dispatch(updateCostFilter(selectedCost));
    },
  };
};

CostSelect.propTypes = {
  loading: PropTypes.any,
  data: PropTypes.any.isRequired,
  errors: PropTypes.string,
  fetchCosts: PropTypes.func.isRequired,
  updateCostFilter: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CostSelect);
