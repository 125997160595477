enum ContentTypes {
  ORGANISATION = "organisation",
  PARTNER_BRAND_LOGO = "partnerBrandLogo",
  SERVICE_CATEGORY = "serviceCategory",
  SERVICE_CONDITION = "serviceEligibilityCondition",
  SERVICE_COST = "serviceCost",
  SERVICE_STATE = "serviceState",
  SERVICE_TYPE = "serviceTypeContentType",
  HERO = "hero",
  WELCOME_CONTENT = "welcomeContent",
  FOOTER = "footer",
}

export default ContentTypes;
