export interface SelectedCost {
  data: string[];
}

export interface SelectedCostState {
  readonly data: string[];
}

export enum CostLabel {
  FREE_ONLY = "Free",
  LOW_COST_ONLY = "Service may incur a cost",
  SUBSIDY_ONLY = "Subsidy",
  FREE_AND_LOW_COST = "Free, some services may incur a cost",
  FREE_AND_SUBSIDY = "Free, subsidy may be available",
  DEFAULT = "Some services may incur a cost or provide subsidy",
}
