import actionTypes from "../../redux/actionTypes";
import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../../redux/initialState";
import ContentfulService from "../../services/contentfulService";
import ContentType from "../../services/contentTypes";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PartnerLogo } from "./partnerLogoTypes";

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, PartnerLogo, Action<string>>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchPartnerLogo: ActionCreator<ThunkAction<void, ApplicationState, any, Action<string>>> = slug => {
  return async (dispatch: Dispatch): Promise<Action<any>> => {
    try {
      const contentfulService = new ContentfulService();
      const entry: any = await contentfulService.getContentTypeFilterBySlug(ContentType.PARTNER_BRAND_LOGO, slug);
      // eslint-disable-next-line prefer-const
      let partnerLogo: PartnerLogo = { id: "", codeId: "", slug: slug, url: "", title: "" };
      if (entry?.items?.length > 0) {
        const item: any = entry.items[0];
        partnerLogo.codeId = item.sys.id;
        partnerLogo.id = item.sys.id;
        partnerLogo.slug = item.fields.slug;
        partnerLogo.url = item.fields.logo.fields.file.url;
      }
      return dispatch({
        type: actionTypes.LOAD_PARTNER_LOGO_SUCCESS,
        payload: partnerLogo,
      });
    } catch (e) {
      return dispatch({
        type: actionTypes.LOAD_CONDITION_ERROR,
        payload: e,
      });
    }
  };
};
