import { Reducer } from "redux";

import { SelectedServiceType, SelectedServiceTypeState } from "./selectedServiceTypeTypes";
import actionTypes from "../../redux/actionTypes";
export const initialState: SelectedServiceTypeState = {
  data: [],
};

const selectedServiceTypeReducer: Reducer<SelectedServiceTypeState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SERVICE_TYPE_FILTER_SUCCESS: {
      const serviceTypeFilterUpdated = action.payload as SelectedServiceType;
      if (serviceTypeFilterUpdated.isChecked && !state.data.includes(serviceTypeFilterUpdated.id)) {
        return { ...state, data: state.data.concat(serviceTypeFilterUpdated.id) };
      } else if (state.data.includes(serviceTypeFilterUpdated.id)) {
        return { ...state, data: state.data.filter(item => item !== serviceTypeFilterUpdated.id) };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default selectedServiceTypeReducer;
