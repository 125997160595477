import React from "react";
import { GelParagraph, GelBoxLayout } from "@tal-gel/components";
import "../organisationCard.css";
import { Organisation } from "../../../features/organisation/organisationTypes";
import OrganisationContactInfo from "../organisationContactInfo";
import OrganisationServiceTypeInfo from "../organisationServiceTypeInfo";
import OrganisationModalAdditionalInfo from "./organisationModalAdditionalInfo";

interface propsFromComponent {
  item: Organisation;
}

type Props = propsFromComponent;

const OrganisationMobileModal: React.FC<Props> = ({ item }) => {
  return (
    <GelBoxLayout vertical alignment={"stretch"}>
      <GelParagraph>{item.description}</GelParagraph>
      <OrganisationContactInfo item={item} />
      <OrganisationModalAdditionalInfo item={item} />
      <OrganisationServiceTypeInfo item={item} />
    </GelBoxLayout>
  );
};

export default OrganisationMobileModal;
