import React from "react";
import {
  GelRowLayout,
  GelParagraph,
  GelIcon,
  GelHeading5,
  GelModal,
  GelBoxLayout,
  GelVisible,
  GelHidden,
  GelButton,
} from "@tal-gel/components";
import "./organisationCard.css";
import { Organisation } from "../../features/organisation/organisationTypes";
import OrganisationMobileModal from "./modal/organisationModalMobile";
import OrganisationModal from "./modal/organisationModal";
import OrganisationServiceTypeInfo from "./organisationServiceTypeInfo";
import OrganisationContactInfo from "./organisationContactInfo";
import { reportCardDisplayed } from "../../services/utmService";
import { getGelTokens } from "@tal-gel/theming";

interface propsFromComponent {
  item: Organisation;
}

type Props = propsFromComponent;

const OrganisationCard: React.FC<Props> = ({ item }) => {
  const [organisationModalVisible, setOrganisationModalVisible] = React.useState(false);

  const openModal = () => {
    if (!organisationModalVisible) {
      setOrganisationModalVisible(true);
      reportCardDisplayed(item.externalName);
    }
  };

  return (
    <div className="card" onClick={() => openModal()}>
      <GelRowLayout gutter={"medium"}>
        <GelBoxLayout space={[1, "auto"]} distribution={"spaceBetween"}>
          <GelHeading5>{item.externalName}</GelHeading5>
          <GelHidden>
            <GelIcon name="Bookmark" color={getGelTokens().global.themeColorIconDefault} width="26" height="26" />
          </GelHidden>
        </GelBoxLayout>
        <GelParagraph>{item.description}</GelParagraph>
        <GelRowLayout gutter="medium">
          <OrganisationContactInfo item={item} />
          <OrganisationServiceTypeInfo item={item} />
          <GelButton secondary small iconLeft="Plus">
            View Additional Info
          </GelButton>
        </GelRowLayout>
      </GelRowLayout>
      <GelModal
        width={720}
        height={"auto"}
        open={organisationModalVisible}
        onClose={() => setOrganisationModalVisible(false)}
        title={<GelHeading5>{item.externalName}</GelHeading5>}
      >
        <GelVisible md>
          <OrganisationModal item={item} />
        </GelVisible>
        <GelVisible xs sm only>
          <OrganisationMobileModal item={item} />
        </GelVisible>
      </GelModal>
    </div>
  );
};

export default OrganisationCard;
