import actionTypes from "../../redux/actionTypes";
import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../../redux/initialState";
import { SelectedCost } from "./selectedCostTypes";

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, SelectedCost, Action<string>>>;

export const updateCostFilter: ActionCreator<ThunkAction<void, ApplicationState, any, Action<string>>> = selectedCost => {
  return (dispatch: Dispatch): Action => {
    try {
      return dispatch({
        type: actionTypes.UPDATE_COST_FILTER_SUCCESS,
        payload: selectedCost,
      });
    } catch (e) {
      return dispatch({
        type: actionTypes.UPDATE_COST_FILTER_ERROR,
        payload: e,
      });
    }
  };
};
