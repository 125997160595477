import React, { useContext, useEffect } from "react";

import Filter from "../../components/filter/filter";
import OrganisationList from "../organisation/organisationList";
import HeroContent from "../hero/hero";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import PropTypes from "prop-types";

import {
  GelRowLayout,
  GelParagraph,
  GelButton,
  GelVisible,
  GelLink,
  GelModal,
  GelHeading2,
  GelHeading5,
  GelScreenDetectorContext,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";

import { fetchWelcomeContent } from "../../features/welcomeContent/welcomeContentActions";
import { ApplicationState } from "../../redux/initialState";
import { WelcomeContent } from "../../features/welcomeContent/welcomeContentTypes";

interface PropsFromState {
  loading: boolean;
  data: WelcomeContent;
  errors?: string;
}

interface propsFromDispatch {
  fetchWelcomeContent: () => any;
}

type AllProps = PropsFromState & propsFromDispatch;

function isEmptyObject(obj: any) {
  return Object.keys(obj).length === 0;
}

const Search: React.FC<AllProps> = ({ loading, errors, data, fetchWelcomeContent }) => {
  useEffect(() => {
    if (isEmptyObject(data)) {
      fetchWelcomeContent();
    }
  }, [data, fetchWelcomeContent]);

  const filter = React.createRef<HTMLDivElement>();
  const [modalVisible, setModalVisible] = React.useState(false);
  const { screen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <GelVisible md lg xl only>
          <HeroContent />
        </GelVisible>
      </div>
      <GelRowLayout alignment="center">
        <GelRowLayout
          alignment="center"
          style={{
            paddingTop: 52,
            paddingBottom: 30,
            borderBottom: "1px solid #e1e1e1",
            width: "100vw",
          }}
        >
          <GelRowLayout gutter={36} style={{ maxWidth: 1080 }}>
            <GelHeading2 style={{ margin: "0" }}>{data.welcomeTitle}</GelHeading2>
            <GelParagraph>{data.welcomeDescription}</GelParagraph>
            <GelParagraph>{data.welcomeDescription2}</GelParagraph>
            <div>
              {isLargerThanSmScreen(screen) ? (
                <GelButton
                  style={{ marginBottom: 22 }}
                  primary
                  iconRight="ArrowDown"
                  large
                  onClick={() => {
                    if (filter && filter.current) {
                      filter.current.scrollIntoView();
                    }
                  }}
                >
                  Get Started
                </GelButton>
              ) : (
                <GelRowLayout>
                  <GelButton iconRight="ArrowDown" primary large onClick={() => setModalVisible(true)}>
                    Get Started
                  </GelButton>
                  <br />
                  <GelLink onClick={() => setModalVisible(true)}>Search filters</GelLink>
                </GelRowLayout>
              )}
            </div>
          </GelRowLayout>
        </GelRowLayout>
        <GelVisible xs sm only>
          <GelModal
            width={"100%"}
            height={"100%"}
            open={modalVisible}
            onClose={() => setModalVisible(false)}
            title={<GelHeading5>Filter</GelHeading5>}
          >
            <Filter />
            <br />
            <GelButton primary large onClick={() => setModalVisible(false)}>
              Filter
            </GelButton>
          </GelModal>
        </GelVisible>
        <GelVisible md>
          <div ref={filter} id={"filter"}>
            <GelRowLayout style={{ maxWidth: 1080, padding: "44px 0" }}>
              <Filter key={"desktop"} />
            </GelRowLayout>
          </div>
        </GelVisible>
        <GelRowLayout
          alignment={"center"}
          style={{
            backgroundColor: getGelTokens().global.themeColorBackgroundLight,
            width: "100vw",
            paddingBottom: "64px",
            borderTop: "1px solid #e1e1e1",
          }}
        >
          <OrganisationList />
        </GelRowLayout>
      </GelRowLayout>
    </>
  );
};

const mapStateToProps = ({ welcomeContent }: ApplicationState) => ({
  loading: welcomeContent.loading,
  errors: welcomeContent.errors,
  data: welcomeContent.data,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchWelcomeContent: () => {
      dispatch(fetchWelcomeContent());
    },
  };
};

Search.propTypes = {
  loading: PropTypes.any,
  errors: PropTypes.string,
  data: PropTypes.any.isRequired,
  fetchWelcomeContent: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
