import { Reducer } from "redux";

import { FooterType, FooterTypeState } from "./footerTypes";
import actionTypes from "../../redux/actionTypes";
export const initialState: FooterTypeState = {
  data: {} as FooterType,
  errors: undefined,
  loading: false,
};

const footerTypeReducer: Reducer<FooterTypeState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_FOOTER_SUCCESS: {
      const footerContent = action.payload as FooterType;
      return { ...state, loading: true, data: footerContent };
    }
    default: {
      return state;
    }
  }
};

export default footerTypeReducer;
