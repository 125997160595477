import { Reducer } from "redux";

import { ConditionState } from "./conditionTypes";
import actionTypes from "../../redux/actionTypes";
export const initialState: ConditionState = {
  data: [],
  errors: undefined,
  loading: false,
};

const conditionReducer: Reducer<ConditionState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_CONDITION_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case actionTypes.LOAD_CONDITION_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default conditionReducer;
