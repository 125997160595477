import { createStore, applyMiddleware, Store, compose } from "redux";
import { History } from "history";
import { routerMiddleware } from "connected-react-router";
import { createRootReducer } from "./rootReducer";
import thunk from "redux-thunk";
import { ApplicationState } from "./initialState";

export default function configureStore(history: History, initialState: ApplicationState): Store<ApplicationState> {
  const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(createRootReducer(history), initialState, composeEnhancer(applyMiddleware(routerMiddleware(history), thunk)));
  return store;
}
