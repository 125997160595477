import Search from "./components/search/Search";
import PageNotFound from "./components/PageNotFound";

export default [
  {
    path: "/",
    component: Search,
    target: "",
    isExact: false,
    isPrivate: false,
  },
  {
    path: "/search",
    component: Search,
    target: "",
    isExact: true,
    isPrivate: false,
  },
  {
    path: "/error",
    component: PageNotFound,
    target: "",
    isExact: true,
    isPrivate: false,
  },
];
