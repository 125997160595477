import { Reducer } from "redux";

import { SelectedLocation, SelectedLocationState } from "./selectLocationTypes";
import actionTypes from "../../redux/actionTypes";
export const initialState: SelectedLocationState = {
  data: "",
};

const selectedCostReducer: Reducer<SelectedLocationState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LOCATION_FILTER_SUCCESS: {
      const locationFilter = action.payload as SelectedLocation;
      if (locationFilter) {
        const value = locationFilter.id;
        return { ...state, data: value };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default selectedCostReducer;
