import actionTypes from "../../redux/actionTypes";
import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../../redux/initialState";
import ContentfulService from "../../services/contentfulService";
import ContentType from "../../services/contentTypes";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FooterType } from "./footerTypes";

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, FooterType, Action<string>>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchFooter: ActionCreator<ThunkAction<void, ApplicationState, any, Action<string>>> = () => {
  let slug = window.location.pathname.substr(1);
  if (slug === "") {
    slug = "tal";
  }
  return async (dispatch: Dispatch): Promise<Action<any>> => {
    try {
      const contentfulService = new ContentfulService();
      const entries: any = await contentfulService.getContentTypeFilterBySlugTheme(ContentType.FOOTER, slug);
      // eslint-disable-next-line prefer-const
      let footerContent: FooterType = { id: "", footerLogo: "", footerLogoUrl: "", footerCaption: "", footerDescription: "" };
      if (entries?.items?.length > 0) {
        const item: any = entries.items[0];
        footerContent.id = item.sys.id;
        footerContent.footerLogo = item.fields.logo.fields.file.url;
        footerContent.footerLogoUrl = item.fields.logoUrl;
        footerContent.footerDescription = item.fields.description;
        footerContent.footerCaption = item.fields.caption;
      }
      return dispatch({
        type: actionTypes.LOAD_FOOTER_SUCCESS,
        payload: footerContent,
      });
    } catch (e) {
      console.log("ERROR");
      return dispatch({
        type: actionTypes.LOAD_FOOTER_ERROR,
        payload: e,
      });
    }
  };
};
