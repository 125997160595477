import actionTypes from "../../redux/actionTypes";
import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../../redux/initialState";
import ContentfulService from "../../services/contentfulService";
import { Category, Organisation, OrganisationServiceType } from "./organisationTypes";
import ContentType from "../../services/contentTypes";
import { ServiceType } from "../serviceType/serviceTypeTypes";
import { Cost } from "../cost/costTypes";
import { State, LocationLabel } from "../state/stateTypes";
import { Condition } from "../condition/conditionTypes";
import { CostLabel } from "../costFilter/selectedCostTypes";

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

const mapServiceTypes = (serviceTypes: any): OrganisationServiceType[] => {
  return serviceTypes.map((stItem: { sys: { id: string }; fields: OrganisationServiceType }) => ({
    id: stItem.sys.id,
    internalName: stItem.fields.internalName,
    externalName: stItem.fields.externalName,
    description: stItem.fields.description,
    codeId: stItem.fields.codeId,
    serviceType: stItem.fields.serviceType ? mapServiceType(stItem.fields.serviceType) : null,
    states: stItem.fields.states ? mapStates(stItem.fields.states) : null,
    cost: stItem.fields.cost ? mapCost(stItem.fields.cost) : null,
  }));
};

const mapServiceType = (serviceTypeEntry: any): ServiceType => {
  return {
    id: serviceTypeEntry.sys.id,
    internalName: serviceTypeEntry.fields.internalName,
    externalName: serviceTypeEntry.fields.externalName,
    codeId: serviceTypeEntry.fields.codeId,
  };
};

const mapCost = (costEntry: any): Cost => {
  return {
    id: costEntry.sys.id,
    internalName: costEntry.fields.internalName,
    externalName: costEntry.fields.externalName,
    codeId: costEntry.fields.codeId,
  };
};

const mapStates = (statesEntry: any): State[] => {
  return statesEntry.map((stateItem: { sys: { id: string }; fields: State }) => ({
    id: stateItem.sys.id,
    internalName: stateItem.fields.internalName,
    externalName: stateItem.fields.externalName,
    codeId: stateItem.fields.codeId,
  }));
};

const mapCategory = (categoryEntry: any): Category => {
  return {
    id: categoryEntry.sys.id,
    internalName: categoryEntry.fields.internalName,
    externalName: categoryEntry.fields.externalName,
    codeId: categoryEntry.fields.codeId,
  };
};

const mapConditions = (conditionEntry: any): Condition[] => {
  return conditionEntry.map((conditionItem: { sys: { id: string }; fields: Condition }) => ({
    id: conditionItem.sys.id,
    internalName: conditionItem.fields.internalName,
    externalName: conditionItem.fields.externalName,
    codeId: conditionItem.fields.codeId,
  }));
};

const isOrganisationNationwide = (serviceTypes: any): boolean => {
  let isNationwide = true;
  serviceTypes.forEach((serviceType: any) => {
    if (serviceType.fields.states.length < 8) isNationwide = false;
  });
  return isNationwide;
};

const getCostLabel = (serviceTypes: any): string => {
  const costCounts = serviceTypes.reduce(
    (p: any, c: any) => {
      const name = c.fields.cost.fields.codeId;
      if (!Object.prototype.hasOwnProperty.call(p, name)) {
        p[name] = 0;
      }
      p[name]++;
      return p;
    },
    { free: 0, lowCost: 0, subsidy: 0 },
  );
  return costLabelFromCounts(costCounts);
};

const costLabelFromCounts = (costCounts: any): any => {
  if (!costCounts || (costCounts.free === 0 && costCounts.lowCost === 0 && costCounts.subsidy === 0)) return "";
  //different combinations
  if (costCounts.free > 0 && costCounts.lowCost === 0 && costCounts.subsidy === 0) return CostLabel.FREE_ONLY;
  if (costCounts.free === 0 && costCounts.lowCost > 0 && costCounts.subsidy === 0) return CostLabel.LOW_COST_ONLY;
  if (costCounts.free === 0 && costCounts.lowCost === 0 && costCounts.subsidy > 0) return CostLabel.SUBSIDY_ONLY;
  if (costCounts.free > 0 && costCounts.lowCost > 0 && costCounts.subsidy === 0) return CostLabel.FREE_AND_LOW_COST;
  if (costCounts.free > 0 && costCounts.lowCost === 0 && costCounts.subsidy > 0) return CostLabel.FREE_AND_SUBSIDY;
  if (costCounts.lowCost > 0 && costCounts.subsidy > 0) return CostLabel.DEFAULT;
};

export const fetchOrganisations: AppThunk = () => {
  return async (dispatch: Dispatch): Promise<Action> => {
    try {
      const contentfulService = new ContentfulService();
      const organisations: any = await contentfulService.getContentType(ContentType.ORGANISATION);
      const validOrganisations = organisations.items
        .filter(function (organisation: any) {
          //check if organisation has no unpublished services, states or costs
          return serviceTypeExists(organisation) && isServiceTypeValid(organisation.fields.serviceTypes);
        })
        .map((validOrganisation: { sys: { id: string }; fields: Organisation }) => ({
          id: validOrganisation.sys.id,
          internalName: validOrganisation.fields.internalName,
          externalName: validOrganisation.fields.externalName,
          description: validOrganisation.fields.description,
          comment: validOrganisation.fields.comment,
          website: validOrganisation.fields.website,
          phoneNumber: validOrganisation.fields.phoneNumber,
          codeId: validOrganisation.fields.codeId,
          category: validOrganisation.fields.category ? mapCategory(validOrganisation.fields.category) : null,
          conditions: validOrganisation.fields.conditions ? mapConditions(validOrganisation.fields.conditions) : null,
          serviceTypes: mapServiceTypes(validOrganisation.fields.serviceTypes),
          locationLabel: isOrganisationNationwide(validOrganisation.fields.serviceTypes) ? LocationLabel.NATIONWIDE : LocationLabel.LIMITED,
          costLabel: getCostLabel(validOrganisation.fields.serviceTypes),
        }));
      return dispatch({
        type: actionTypes.LOAD_ORGANISATION_SUCCESS,
        payload: validOrganisations,
      });
    } catch (e) {
      return dispatch({
        type: actionTypes.LOAD_ORGANISATION_ERROR,
        payload: e,
      });
    }
  };
};

const serviceTypeExists = (item: any): boolean => {
  return item.fields?.serviceTypes?.length > 0 && item.fields.serviceTypes[0].fields; //sanity check
};

const isServiceTypeValid = (serviceTypes: any): boolean => {
  return (
    serviceTypes.filter((serviceType: any): boolean => {
      return serviceType.fields && isCostAndStateValid(serviceType);
    }).length === serviceTypes.length
  );
};

const isCostAndStateValid = (serviceType: any): boolean => {
  if (serviceType.fields?.cost?.fields && serviceType.fields.states?.length > 0) {
    //sanity check
    return (
      serviceType.fields.states.filter((state: any) => {
        return state.fields;
      }).length === serviceType.fields.states.length
    );
  } else return false;
};
