import actionTypes from "../../redux/actionTypes";
import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../../redux/initialState";
import ContentfulService from "../../services/contentfulService";
import ContentType from "../../services/contentTypes";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { HeroType } from "./heroTypes";

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, HeroType, Action<string>>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchHeroContent: ActionCreator<ThunkAction<void, ApplicationState, any, Action<string>>> = () => {
  let slug = window.location.pathname.substr(1);
  if (slug === "") {
    slug = "tal";
  }
  return async (dispatch: Dispatch): Promise<Action<any>> => {
    try {
      const contentfulService = new ContentfulService();
      const entries: any = await contentfulService.getContentTypeFilterBySlugTheme(ContentType.HERO, slug);
      // eslint-disable-next-line prefer-const
      let heroContent: HeroType = { id: "", heroTitle: "", heroDescription: "", heroImage: "" };
      if (entries?.items?.length > 0) {
        const item: any = entries.items[0];
        heroContent.id = item.sys.id;
        heroContent.heroTitle = item.fields.heroTitle;
        heroContent.heroDescription = item.fields.heroDescription;
        heroContent.heroImage = item.fields.heroImage.fields.file.url;
      }
      return dispatch({
        type: actionTypes.LOAD_HERO_SUCCESS,
        payload: heroContent,
      });
    } catch (e) {
      console.log("ERROR");
      return dispatch({
        type: actionTypes.LOAD_HERO_ERROR,
        payload: e,
      });
    }
  };
};
