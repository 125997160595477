import { Reducer } from "redux";

import { CostState } from "./costTypes";
import actionTypes from "../../redux/actionTypes";
export const initialState: CostState = {
  data: [],
  errors: undefined,
  loading: false,
};

const costReducer: Reducer<CostState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_COST_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case actionTypes.LOAD_COST_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default costReducer;
