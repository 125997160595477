import { Reducer } from "redux";

import { SelectedCost, SelectedCostState } from "./selectedCostTypes";
import actionTypes from "../../redux/actionTypes";
export const initialState: SelectedCostState = {
  data: [],
};

const selectedCostReducer: Reducer<SelectedCostState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_COST_FILTER_SUCCESS: {
      const costFilter = action.payload as SelectedCost;
      if (costFilter) {
        const value = costFilter;
        return { ...state, data: value.data };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default selectedCostReducer;
