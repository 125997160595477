import { Reducer } from "redux";

import { OrganisationState } from "./organisationTypes";
import actionTypes from "../../redux/actionTypes";
export const initialState: OrganisationState = {
  data: [],
  errors: undefined,
  loading: false,
};

const organisationReducer: Reducer<OrganisationState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_ORGANISATION_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case actionTypes.LOAD_ORGANISATION_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default organisationReducer;
