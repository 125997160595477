import { Reducer } from "redux";

import { StateState } from "./stateTypes";
import actionTypes from "../../redux/actionTypes";
export const initialState: StateState = {
  data: [],
  errors: undefined,
  loading: false,
};

const stateReducer: Reducer<StateState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_STATE_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case actionTypes.LOAD_STATE_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default stateReducer;
