import { Condition } from "../features/condition/conditionTypes";
import { Cost } from "../features/cost/costTypes";
import { Organisation } from "../features/organisation/organisationTypes";
import { ServiceType } from "../features/serviceType/serviceTypeTypes";
import { State } from "../features/state/stateTypes";

declare let window: any;
//Functions for sending data for Adobe Analytics

// If search filter is used, we track it
export const reportSearchFilterApplied = (
  organisations: Organisation[],
  selectedServiceTypes: string[],
  selectedLocation: string,
  selectedConditions: string[],
  selectedCosts: string[],
  allServiceTypes: ServiceType[],
  allStates: State[],
  allConditions: Condition[],
  allCosts: Cost[],
) => {
  /* eslint-disable */
  let filters: [{ fieldName: string; fieldValue: string }] = [{ fieldName: "", fieldValue: "" }];
  /* eslint-enable */

  if (selectedServiceTypes?.length > 0) {
    filters.push({
      fieldName: "Service Type",
      fieldValue: selectedServiceTypes.map(st => allServiceTypes.find(s => s.id === st)?.externalName).join(),
    });
  }

  if (selectedLocation?.length > 0) {
    const location = allStates.find(s => s.id === selectedLocation)?.externalName;
    if (location) {
      filters.push({
        fieldName: "Location",
        fieldValue: location,
      });
    }
  }

  if (selectedConditions?.length > 0) {
    filters.push({
      fieldName: "Condition",
      fieldValue: selectedConditions.map(selectedCondition => allConditions.find(c => c.id === selectedCondition)?.externalName).join(),
    });
  }

  if (selectedCosts?.length > 0) {
    filters.push({
      fieldName: "Cost",
      fieldValue: selectedCosts.map(selectedCost => allCosts.find(c => c.id === selectedCost)?.externalName).join(),
    });
  }

  const data = {
    event: "Search Filter Applied",
    searchFilter: {
      searchResults: organisations ? organisations.length : 0,
      data: filters.splice(1),
    },
  };
  sendUtmData(data);
};

// record title and link when a Link is clicked on the card.
export const reportCardLinkClicked = (link: string, title: string) => {
  const data = {
    event: "Card Link Clicked",
    card: {
      title: title,
      link: link,
    },
  };
  sendUtmData(data);
};

// if a card is clicked we send the name of it.
export const reportCardDisplayed = (cardTitle: string) => {
  const data = {
    event: "Card Displayed",
    card: {
      title: cardTitle,
    },
  };
  sendUtmData(data);
};

const sendUtmData = (data: any) => {
  window.appEventData.push(data);
};
