import React from "react";
import { GelRowLayout, GelParagraph, GelIcon, GelBoxLayout, GelTooltip, GelLabel } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import "./organisationCard.css";
import { Organisation } from "../../features/organisation/organisationTypes";

interface propsFromComponent {
  item: Organisation;
}

type Props = propsFromComponent;

const OrganisationServiceTypeInfo: React.FC<Props> = ({ item }) => {
  return (
    <GelRowLayout gutter="small">
      <GelLabel className="service-label">Service Type</GelLabel>
      <GelRowLayout gutter={"xsmall"}>
        {item.serviceTypes.map(organisationServiceType => (
          <GelRowLayout key={organisationServiceType.id}>
            <GelBoxLayout space={[1, "auto"]} distribution="spaceBetween" alignment={"start"}>
              <GelBoxLayout space={["auto", 1]} distribution="start" gap="xsmall" alignment="start">
                <GelIcon
                  name="CheckSmall"
                  color={getGelTokens().global.themeColorIconDefault}
                  width="18"
                  height="18"
                  style={{ paddingTop: 1 }}
                />
                <GelParagraph>{organisationServiceType.serviceType.externalName}</GelParagraph>
              </GelBoxLayout>
              <GelTooltip color={getGelTokens().global.themeColorIconDefault} selfAdaptive fixedAtTopLevel style={{ paddingTop: 3 }}>
                <GelRowLayout gutter="medium">
                  {organisationServiceType.description}
                  <GelBoxLayout distribution="start" gap="xsmall" space={["auto", 1]}>
                    <GelIcon
                      name={"LocationPin"}
                      style={{ paddingTop: 1 }}
                      color={getGelTokens().global.themeColorIconDefault}
                      height={"18px"}
                      width={"18px"}
                    />
                    {organisationServiceType.states
                      .map(state => state.internalName)
                      .join(", ")
                      .toString()}
                  </GelBoxLayout>
                  <GelBoxLayout
                    distribution="start"
                    gap="xsmall"
                    space={["auto", 1]}
                    style={{ display: organisationServiceType.cost ? "flex" : "none" }}
                  >
                    <GelIcon name={"ConceptsThemesDollarSign"} brand color="#80C342" height={"18px"} width={"18px"} />
                    {organisationServiceType.cost.externalName}
                  </GelBoxLayout>
                </GelRowLayout>
              </GelTooltip>
            </GelBoxLayout>
          </GelRowLayout>
        ))}
      </GelRowLayout>
    </GelRowLayout>
  );
};

export default OrganisationServiceTypeInfo;
