enum ActionTypes {
  LOAD_CATEGORY_ERROR = "LOAD_CATEGORY_ERROR",
  LOAD_CATEGORY_SUCCESS = "LOAD_CATEGORY_SUCCESS",
  LOAD_CONDITION_ERROR = "LOAD_CONDITION_SUCCESS",
  LOAD_CONDITION_SUCCESS = "LOAD_CONDITION_SUCCESS",
  LOAD_COST_ERROR = "LOAD_COST_ERROR",
  LOAD_COST_SUCCESS = "LOAD_COST_SUCCESS",
  LOAD_HERO_ERROR = "LOAD_HERO_ERROR",
  LOAD_HERO_SUCCESS = "LOAD_HERO_SUCCESS",
  LOAD_FOOTER_ERROR = "LOAD_FOOTER_ERROR",
  LOAD_FOOTER_SUCCESS = "LOAD_FOOTER_SUCCESS",
  LOAD_WELCOME_CONTENT_ERROR = "LOAD_WELCOME_CONTENT_ERROR",
  LOAD_WELCOME_CONTENT_SUCCESS = "LOAD_WELCOME_CONTENT_SUCCESS",
  LOAD_DISCLAIMER_ERROR = "LOAD_DISCLAIMER_ERROR",
  LOAD_DISCLAIMER_SUCCESS = "LOAD_DISCLAIMER_SUCCESS",
  LOAD_ORGANISATION_SUCCESS = "LOAD_ORGANISATION_SUCCESS",
  LOAD_ORGANISATION_ERROR = "LOAD_ORGANISATION_ERROR",
  LOAD_SERVICE_ERROR = "LOAD_SERVICE_ERROR",
  LOAD_SERVICE_SUCCESS = "LOAD_SERVICE_SUCCESS",
  LOAD_SERVICE_TYPE_ERROR = "LOAD_SERVICE_TYPE_ERROR",
  LOAD_SERVICE_TYPE_SUCCESS = "LOAD_SERVICE_TYPE_SUCCESS",
  UPDATE_CONDITION_FILTER_ERROR = "UPDATE_CONDITION_FILTER_ERROR",
  UPDATE_CONDITION_FILTER_SUCCESS = "UPDATE_CONDITION_FILTER_SUCCESS",
  UPDATE_COST_FILTER_ERROR = "UPDATE_COST_FILTER_ERROR",
  UPDATE_COST_FILTER_SUCCESS = "UPDATE_COST_FILTER_SUCCESS",
  UPDATE_LOCATION_FILTER_ERROR = "UPDATE_LOCATION_FILTER_ERROR",
  UPDATE_LOCATION_FILTER_SUCCESS = "UPDATE_LOCATION_FILTER_SUCCESS",
  UPDATE_SERVICE_TYPE_FILTER_ERROR = "UPDATE_SERVICE_TYPE_FILTER_ERROR",
  UPDATE_SERVICE_TYPE_FILTER_SUCCESS = "UPDATE_SERVICE_TYPE_FILTER_SUCCESS",
  LOAD_STATE_ERROR = "LOAD_STATE_ERROR",
  LOAD_STATE_SUCCESS = "LOAD_STATE_SUCCESS",
  LOAD_PARTNER_LOGO_SUCCESS = "LOAD_PARTNER_LOGO",
}

export default ActionTypes;
