import { Reducer } from "redux";

import { WelcomeContentState, WelcomeContent } from "./welcomeContentTypes";
import actionTypes from "../../redux/actionTypes";
export const initialState: WelcomeContentState = {
  data: {} as WelcomeContent,
  errors: undefined,
  loading: false,
};

const welcomeContentReducer: Reducer<WelcomeContentState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_WELCOME_CONTENT_SUCCESS: {
      const welcomeContent = action.payload as WelcomeContent;
      return { ...state, loading: true, data: welcomeContent };
    }
    default: {
      return state;
    }
  }
};

export default welcomeContentReducer;
