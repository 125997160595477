import React from "react";
import { GelRowLayout, GelParagraph, GelIcon, GelBoxLayout } from "@tal-gel/components";
import "../organisationCard.css";
import { Organisation } from "../../../features/organisation/organisationTypes";
import { getGelTokens } from "@tal-gel/theming";
interface propsFromComponent {
  item: Organisation;
}

type Props = propsFromComponent;

const OrganisationModalAdditionalInfo: React.FC<Props> = ({ item }) => {
  return (
    <GelBoxLayout alignment="stretch" distribution="start" space={["auto", 1]}>
      <GelRowLayout gap="xsmall">
        <GelBoxLayout
          space={["auto", 1]}
          distribution="start"
          gap="xsmall"
          gutter={"xsmall"}
          style={{ display: item.locationLabel ? "flex" : "none" }}
        >
          <GelIcon name="LocationPin" color={getGelTokens().global.themeColorIconDefault} width="18" height="18" />
          <GelParagraph>
            <span className={"black"}>{item.locationLabel}</span>
          </GelParagraph>
        </GelBoxLayout>
        <GelBoxLayout
          space={["auto", 1]}
          distribution="start"
          gap="xsmall"
          gutter={"xsmall"}
          style={{ display: item.costLabel ? "flex" : "none" }}
        >
          <GelIcon name="ConceptsThemesDollarSign" brand color={getGelTokens().global.themeColorIconDefault} width="18" height="18" />
          <GelParagraph>
            <span className={"black"}>{item.costLabel}</span>
          </GelParagraph>
        </GelBoxLayout>
      </GelRowLayout>
    </GelBoxLayout>
  );
};

export default OrganisationModalAdditionalInfo;
