import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import conditionReducer from "../features/condition/conditionReducers";
import costReducers from "../features/cost/costReducers";
import stateReducers from "../features/state/stateReducers";
import serviceTypeReducers from "../features/serviceType/serviceTypeReducers";
import serviceReducer from "../features/service/serviceReducers";
import selectedServiceTypeReducer from "../features/serviceTypeFilter/selectedServiceTypeReducers";
import organisationReducer from "../features/organisation/organisationReducers";
import selectedConditionReducer from "../features/conditionFilter/selectedConditionReducers";
import selectedCostReducer from "../features/costFilter/selectedCostReducers";
import selectedLocationReducer from "../features/locationFilter/selectLocationReducers";
import partnerLogoReducer from "../features/partnerLogo/partnerLogoReducers";
import heroTypeReducer from "../features/hero/heroTypeReducer";
import welcomeContentReducer from "../features/welcomeContent/welcomeContentReducer";
import footerTypeReducer from "../features/footer/footerTypeReducer";

//please add entry to initialState.ts as you add/update new reducers
export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    condition: conditionReducer,
    cost: costReducers,
    state: stateReducers,
    serviceType: serviceTypeReducers,
    service: serviceReducer,
    organisation: organisationReducer,
    selectedServiceType: selectedServiceTypeReducer,
    selectedCondition: selectedConditionReducer,
    selectedCost: selectedCostReducer,
    selectedLocation: selectedLocationReducer,
    partnerLogo: partnerLogoReducer,
    heroType: heroTypeReducer,
    welcomeContent: welcomeContentReducer,
    footerType: footerTypeReducer,
  });
