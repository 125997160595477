import React, { useEffect } from "react";
import { GelIcon, GelRowLayout, GelParagraph, GelCaption, GelBoxLayout, GelLink } from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { FooterType } from "../../features/footer/footerTypes";
import { fetchFooter } from "../../features/footer/footerTypeActions";
import { ApplicationState } from "../../redux/initialState";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Markdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";
interface PropsFromState {
  loading: boolean;
  data: FooterType;
  errors?: string;
}

interface propsFromDispatch {
  fetchFooter: () => any;
}

type AllProps = PropsFromState & propsFromDispatch;

function isEmptyObject(obj: any) {
  return Object.keys(obj).length === 0;
}

const Footer: React.FC<AllProps> = ({ loading, errors, data, fetchFooter }) => {
  useEffect(() => {
    if (isEmptyObject(data)) {
      fetchFooter();
    }
  }, [data, fetchFooter]);

  return (
    <div style={{ padding: "32px  18px" }}>
      <GelBoxLayout
        space={{ xs: "auto", sm: "auto", md: ["auto", 1] }}
        alignment="start"
        distribution="start"
        gap="large"
        style={{ maxWidth: 1080 }}
      >
        <a href={data.footerLogoUrl} rel={"noopener noreferrer"} target="_blank">
          <GelParagraph>
            <img src={data.footerLogo} width="65px" height="24px" alt={"TAL Logo"} />
          </GelParagraph>
        </a>
        <GelRowLayout gutter={"medium"}>
          <GelParagraph inverse>{data.footerDescription}</GelParagraph>
          <GelCaption>
            <Markdown
              rehypePlugins={[rehypeSanitize]}
              components={{
                p(props: any) {
                  return <GelCaption style={{ color: "#abacae" }}>{props?.children}</GelCaption>;
                },
                a(props: any) {
                  return (
                    <GelLink style={{ color: "#abacae" }} rel={"noopener noreferrer"} target="_blank" href={props?.href}>
                      {props?.children}
                      <GelIcon inline name="ExternalLink" width={12} height={12} color={getGelTokens().global.themeColorIconDefault} />
                    </GelLink>
                  );
                },
              }}
            >
              {data?.footerCaption}
            </Markdown>
          </GelCaption>
        </GelRowLayout>
      </GelBoxLayout>
    </div>
  );
};

const mapStateToProps = ({ footerType }: ApplicationState) => ({
  loading: footerType.loading,
  errors: footerType.errors,
  data: footerType.data,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    fetchFooter: () => {
      dispatch(fetchFooter());
    },
  };
};

Footer.propTypes = {
  loading: PropTypes.any,
  errors: PropTypes.string,
  data: PropTypes.any.isRequired,
  fetchFooter: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
