import { configureTheming } from "@tal-gel/theming";
import ThemingPluginTal from "@tal-gel/theming-plugin-tal";
import ThemingPluginAware from "@tal-gel/theming-plugin-aware";
import ThemingPluginAmp from "@tal-gel/theming-plugin-amp";
// import ThemingPluginAmp from "@tal-gel/theming-plugin-amp/dist/lite/main";

import { UiIcons } from "@tal-gel/theming-plugin-tal/dist/lite/ui-icons/main";

import ConceptsThemesQuestion from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/concepts-themes-question";
import HealthHealthPlan from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/health-health-plan";
import ConceptsThemesClock from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/concepts-themes-clock";
import HealthPain from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/health-pain";
import PeopleSinglePerson from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/people-single-person";
import HealthFamilyHistory from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/health-family-history";
import ContactsSocialMail from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/contacts-social-mail";
import HealthHealthCheck from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/health-health-check";
import HealthHealthResults from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/health-health-results";
import ConceptsThemesVolunteerCharity from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/concepts-themes-volunteer-charity";
import PeopleSwimming from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/people-swimming";
import FoodDrinkApple from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/food-drink-apple";
import ConceptsThemesGenderEquality from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/concepts-themes-gender-equality";
import ConceptsThemesAustralia from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/concepts-themes-australia";
import HealthWeight from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/health-weight";
import HealthSkinChanges from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/health-skin-changes";
import HealthSmoking from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/health-smoking";
import ConceptsThemesLightbulb from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/concepts-themes-lightbulb";
import HealthHat from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/health-hat";
import HealthSunscreen from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/health-sunscreen";
import HealthPrimaryPrevention from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/health-primary-prevention";
import FoodDrinkCupcake from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/food-drink-cupcake";
import ConceptsThemesInformation from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/concepts-themes-information";
import ContactsSocialWebsite from "@tal-gel/theming-plugin-tal/dist/lite/brand-icons/contacts-social-website";

const brandIcons = {
  ConceptsThemesQuestion,
  HealthHealthPlan,
  ConceptsThemesClock,
  HealthPain,
  PeopleSinglePerson,
  HealthFamilyHistory,
  ContactsSocialMail,
  HealthHealthCheck,
  HealthHealthResults,
  ConceptsThemesVolunteerCharity,
  PeopleSwimming,
  FoodDrinkApple,
  ConceptsThemesGenderEquality,
  ConceptsThemesAustralia,
  HealthSkinChanges,
  HealthWeight,
  HealthSmoking,
  ConceptsThemesLightbulb,
  HealthHat,
  HealthSunscreen,
  HealthPrimaryPrevention,
  FoodDrinkCupcake,
  ConceptsThemesInformation,
  ContactsSocialWebsite,
};

const themingPluginAmp = ThemingPluginAmp;
themingPluginAmp[`ampUiIcons`] = UiIcons;
themingPluginAmp[`ampBrandIcons`] = brandIcons;

configureTheming({
  plugins: [
    ThemingPluginTal, // Add TAL theme to tal-gel
    ThemingPluginAmp,
    ThemingPluginAware,
  ],
});
